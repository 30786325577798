module MinGat.Browser {
    export class BrowserResult {
        score: number;
        maxScore: number;
        pass: boolean;
        failed: string[];
        warnings: string[];
        constructor(pass: boolean, score: number, maxScore: number, failed?: string[], warnings?: string[]) {
            this.score = score;
            this.maxScore = maxScore;
            this.pass = !!pass;
            this.failed = failed || [];
            this.warnings = warnings || [];
        }
    }

    export class BrowserDetect {
        browser: string;
        version: any;
        versionSearchString: string;
        usePrefered: boolean;
        prefered: string;
        isMobile: boolean;
        private _dataBrowser: any[];

        constructor() {
            this._dataBrowser =
            [
                { string: navigator.userAgent, subString: "Chrome", identity: "Chrome" },
                { string: navigator.userAgent, subString: "MSIE", identity: "Explorer" },
                { string: navigator.userAgent, subString: "Firefox", identity: "Firefox" },
                { string: navigator.userAgent, subString: "Safari", identity: "Safari", prefer: "Version" },
                { string: navigator.userAgent, subString: "Opera", identity: "Opera", prefer: "Version" }
            ];
            this.init();
        }

        init() {
            this.browser = this.searchString(this._dataBrowser) || "Other";
            this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
        }

        searchString(data) {
            for (var i = 0; i < data.length; i++) {
                var dataString = data[i].string;
                this.versionSearchString = data[i].subString;
                this.isMobile = false;

                if (dataString.indexOf(data[i].subString) != -1) {
                    this.usePrefered = false;
                    this.prefered = null;
                    this.isMobile = this.getIsMobile(dataString);
                    if (typeof data[i].prefer == "string" && data[i].prefer.length) {
                        this.usePrefered = dataString.indexOf(data[i].prefer) > -1;
                        if (this.usePrefered) {
                            this.prefered = data[i].prefer;
                        }
                    }

                    return data[i].identity;
                }
            }
        }

        getIsMobile(dataString) : boolean {
            if (dataString.indexOf("Mobile") > -1) {
                return true;
            }
            return false;
        }

        searchVersion(dataString) {
            var index;
            if (this.usePrefered) {
                index = dataString.indexOf(this.prefered);
                if (index >= 0)
                    return parseFloat(dataString.substring(index + this.prefered.length + 1));
            } 

            index = dataString.indexOf(this.versionSearchString);
            if (index == -1) return;
            return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
        }
    }

    export var browserInfo = new BrowserDetect();

    export function checkBrowser(): BrowserResult {
        var accScore = 0;
        var maxScore = 0;
        var scoreSuccessMultiplier = 10;
        var scoreUsefulMultiplier = 5;
        var failed: string[] = [];
        var warnings: string[] = [];

        // Browser detection desktop
        var browserVersionFailed = false;
        if (!Modernizr.touch && !browserInfo.isMobile) {
            if (browserInfo.browser == 'Explorer' && browserInfo.version < 9) {
                failed.push("Internet Exlorer v." + browserInfo.version + " is not supported!");
            }

            if (browserInfo.browser == 'Opera' && browserInfo.version < 10) {
                failed.push("Opera v." + browserInfo.version + " is not supported!");
            }

            if (browserInfo.browser == 'Firefox' && browserInfo.version < 4) {
                failed.push("Firefox v." + browserInfo.version + " is not supported!");
            }

            if (browserInfo.browser == 'Safari' && browserInfo.version < 5.1) {
                failed.push("Safari v." + browserInfo.version + " is not supported!");
            }

            if (browserInfo.browser == 'Chrome' && browserInfo.version < 10) {
                failed.push("Chrome v." + browserInfo.version + " is not supported!");
            }

            browserVersionFailed = failed.length > 0;
        }

        if (Modernizr.hashchange) {
            accScore += scoreSuccessMultiplier;
        } else {
            failed.push("No support for HashChange functionality. MinGat is a single page application (SPA) and this functionality is required for clientside routing.");
        }
        maxScore += scoreSuccessMultiplier;

        if (Modernizr.canvas) {
            accScore += scoreSuccessMultiplier;
        } else {
            failed.push("No support for canvas.");
        }
        maxScore += scoreSuccessMultiplier;

        if (Modernizr.svg) {
            accScore += scoreSuccessMultiplier;
        } else {
            failed.push("No support for 'SVG'.");
        }
        maxScore += scoreSuccessMultiplier;

        if (Modernizr.generatedcontent) {
            accScore += scoreSuccessMultiplier;
        
        } else {
            failed.push("No support for 'generated content' (:before & :after). Essential for usability.");
        }
        maxScore += scoreSuccessMultiplier;

        if (Modernizr.opacity) {
            accScore += scoreSuccessMultiplier;
        } else {
            failed.push("No support for 'opacity'. Essential for usability.");
        }
        maxScore += scoreSuccessMultiplier;

        if (Modernizr.rgba) {
            accScore += scoreSuccessMultiplier;
        } else {
            failed.push("No support for 'rgba'. Essential for usability.");
        }
        maxScore += scoreSuccessMultiplier;

        // Warnings

        if (Modernizr.boxshadow) {
            accScore += scoreSuccessMultiplier;
        } else {
            warnings.push("No support for 'box shadow'. This may decrease usability.");
        }
        maxScore += scoreSuccessMultiplier;

        if (Modernizr.borderradius) {
            accScore += scoreSuccessMultiplier;
        } else {
            warnings.push("No support for 'border radius'. This may decrease usability.");
        }
        maxScore += scoreSuccessMultiplier;

        if (Modernizr.cssgradients) {
            accScore += scoreSuccessMultiplier;
        } else {
            warnings.push("No support for 'css gradients'. This may decrease usability.");
        }
        maxScore += scoreSuccessMultiplier;

        if (Modernizr.textshadow) {
            accScore += scoreSuccessMultiplier;
        } else {
            warnings.push("No support for 'text shadow'. This may decrease usability.");
        }
        maxScore += scoreSuccessMultiplier;

        if (Modernizr.sessionstorage && Modernizr.localstorage) {
            accScore += scoreSuccessMultiplier;
        } else {
            warnings.push("No support for 'session/local storage'. MinGat uses this functionality to temporarily store data for improved reliability, but are not required.");
        }
        maxScore += scoreSuccessMultiplier;

        // Useful features

        if (Modernizr.backgroundsize) {
            accScore += scoreUsefulMultiplier;
        }
        maxScore += scoreUsefulMultiplier;

        if (Modernizr.cssanimations) {
            accScore += scoreUsefulMultiplier;
        }
        maxScore += scoreUsefulMultiplier;

        if (Modernizr.cssreflections) {
            accScore += scoreUsefulMultiplier;
        }
        maxScore += scoreUsefulMultiplier;

        if (Modernizr.fontface) {
            accScore += scoreUsefulMultiplier;
        }
        maxScore += scoreUsefulMultiplier;

        if (Modernizr.history) {
            accScore += scoreUsefulMultiplier;
        }
        maxScore += scoreUsefulMultiplier;

        if (Modernizr.hsla) {
            accScore += scoreUsefulMultiplier;
        }
        maxScore += scoreUsefulMultiplier;

        if (Modernizr.video) {
            accScore += scoreUsefulMultiplier;
        }
        maxScore += scoreUsefulMultiplier;

        if (browserVersionFailed) {
            return new BrowserResult(failed.length == 0, 0, maxScore, failed, warnings); 
        }

        return new BrowserResult(failed.length == 0, accScore, maxScore, failed, warnings); 
    }

} 